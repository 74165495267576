@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap");
$pfontfamily: "Poppins", sans-serif;
$themeColor: #1e22aa;
$themeColorText: #1e22aa;
$warning:#D12D35;
$themeColor2: #49A197;
$themeColor3: #F4F4FB;
$white: #fff;
$h2-color: #101820;
$light-grey:#808080;
$dark-grey:#404040;
$p-color1:#182849;
$green-color: #49a197;
$black-color:#000000;
$h2: 32px;
$p-18-fontsize: 18px;
$p-16-fontsize: 16px;
$p-14-fontsize: 14px;
$fweight:400;
$fweight2:600;
$input-margin: 20px 0;
$width-100: 100%;
$center : center;
$mobile:767px;  




