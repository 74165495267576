@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap");
@import "/src/Global/sass/variables.scss";
@font-face {
  font-family: Museo-Regular;
  src: url(../Global/museo-font/Museo300-Regular.otf);
}
@font-face {
  font-family: Museo-bold;
  src: url(../Global/museo-font/Museo700-Regular.otf);
}

p {
  margin: 0px;
  font-family: $pfontfamily !important;
}
a {
  text-decoration: auto;
}

.black-color {
  color: $black-color;
}
.dark-grey {
  color: $dark-grey;
}
.dark-warning {
  color: $warning;
}
.grey-bg {
  background: #f2f2f2;
}
.light-skyblue-bg {
  background-color: $themeColor3;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
body {
  font-family: $pfontfamily;
  .m-auto {
    margin: 0 auto;
  }
  p {
    color: $p-color1;
    font-weight: 400;
  }
  .light-grey {
    color: $light-grey !important;
  }
  .museo-regular {
    font-family: Museo-Regular;
  }
  .museo-bold {
    font-family: Museo-bold;
  }
  .blue_text {
    color: $themeColorText !important;
  }
  .light-blue {
    color: $p-color1;
  }
  .dark-green {
    color: $themeColor2 !important;
  }
  .font-lg-14 {
    font-size: 14px;
  }
  .font-lg-18 {
    font-size: 18px;
  }
  .font-lg-20 {
    font-size: 20px;
  }
  .font-lg-24 {
    font-size: 24px;
  }
  .font-lg-28 {
    font-size: 28px;
  }
  .font-lg-32 {
    font-size: 32px;
  }
  .fw-600 {
    font-weight: $fweight2;
  }
  .mt-3 {
    margin-top: 20px;
  }
  button {
    font-family: $pfontfamily;
  }
  .pt-lg-45 {
    padding-top: 45px;
  }
  .p-18 {
    font-family: $pfontfamily;
    color: $p-color1;
    font-size: $p-18-fontsize;
    font-weight: $fweight;
  }
  .p-16 {
    font-family: $pfontfamily;
    color: $p-color1;
    font-weight: $fweight;
    font-size: $p-16-fontsize;
  }
  .p-14 {
    font-family: $pfontfamily;
    color: $p-color1;
    font-weight: $fweight;
    font-size: 14px;
  }
  .desktop-none {
    display: none;
  }
  // button
  .formbutton {
    text-align: $center;
  }
  .img-fluid {
    width: 100%;
  }

  .button-primary {
    background: $themeColor;
    color: white;
    border: none;
    display: block;
    border-radius: 8px;
    text-transform: none;
    font-weight: $fweight;
    cursor: pointer;
    font-size: 16px;
    padding: 14px 40px;
    text-decoration: auto;
    &:hover {
      background: $themeColor;
    }
  }
  .light-button-primary {
    background: $themeColor3;
    color: $themeColorText;
    padding: 14px 30px;
    border: none;
    display: block;
    border-radius: 8px;
    text-transform: none;
    font-size: 16px;
    font-weight: $fweight2;
    cursor: pointer;
    text-decoration: auto;
    &:hover {
      background: $themeColor3;
    }
    @media (max-width: $mobile) {
      padding: 12px 20px;
    }
  }
  .button-light {
    background: $themeColor3;
    color: $themeColor;
    padding: 14px 30px;
    border: none;
    border-radius: 8px;
    text-transform: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 1.5rem;
    @media (max-width: $mobile) {
      font-size: 16px;
      padding: 12px 20px;
    }
  }
  .button-secondary-outline {
    border: 1px solid $themeColor2;
    background-color: transparent;
    color: $themeColor2;
    padding: 14px 30px;
    border-radius: 8px;
    text-transform: none;
    font-family: $pfontfamily;
    font-size: 16px;
    font-weight: $fweight;
    cursor: pointer;
    @media (max-width: $mobile) {
      font-size: 16px;
      padding: 12px 20px;
    }
  }
  .button-primary.Mui-disabled {
    background: #bfbfbf;
    color: #ffffff !important;
  }
  .fund-btn button {
    max-width: 380px;
    margin: -40px auto 0px;
    display: block;
  }
  .desktop-hide {
    display: none;
    @media (max-width: $mobile) {
      display: block;
    }
  }
  .mobile-hide {
    @media (max-width: $mobile) {
      display: none;
    }
  }
  .full-height {
    height: 100vh;
    padding-bottom: 15px;
  }
}
@media (max-width: $mobile) {
  body {
    .font-lg-20 {
      font-size: 16px;
    }
    .font-lg-24 {
      font-size: 18px;
    }
    .font-lg-28 {
      font-size: 20px;
    }
    .font-lg-32 {
      font-size: 20px;
    }
    .font-lg-18 {
      font-size: 16px;
    }
    .desktop-none {
      display: block;
    }
    .pt-lg-45 {
      padding-top: 25px;
    }
  }
}
.icon-img {
  position: relative;
  img {
    position: absolute;
    @media (max-width: $mobile) {
      width: 40px;
      position: relative;
    }
  }
}
.xcala-logo-layout {
  img {
    width: 125px;
  }
}
.xcala-logo {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 44px 0px 46px 0px;
  @media (max-width: $mobile) {
    padding: 20px 0px 0px 0px;
  }
  img {
    @media (max-width: $mobile) {
      max-width: 102px;
    }
  }
}

.mobile-none {
  @media (max-width: $mobile) {
    display: none;
  }
}

.break_content {
  display: block;
  padding: 7px 0;
}
.section-padding {
  height: calc(100vh - 206px);
  display: grid;
  place-content: center;
  @media (max-width: $mobile) {
    height: 100%;
    display: block;
    place-content: start;
  }
}
body {
  margin: 0;
  font-family: $pfontfamily;
  box-sizing: border-box;

  .PhoneInputInput {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    outline: white !important;
  }
  .PhoneInput:focus-visible {
    outline: white !important;
    border: none !important;
  }
  .MuiContainer-maxWidthLg {
    max-width: 1372px;
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    padding: 20px 15px;
    border-radius: 16px;
  }
  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    @media (max-width: $mobile) {
      padding: 9px 0px 0;
    }
  }
  .css-hlj6pa-MuiDialogActions-root button {
    margin: 20px 0 0;
    border-radius: 4px;
    padding: 13px 52px;
  }
  .MuiDialog-container {
    .MuiButtonBase-root {
      @media (max-width: $mobile) {
      }
    }
    .MuiDialogActions-root {
      padding: 10px 15px;
    }
  }

  .css-u8u3sx-MuiTypography-root {
    font-family: $pfontfamily;
  }
  .css-mhc70k-MuiGrid-root {
    width: 100%;
    margin-left: 0;
  }

  .active_notification {
    p {
      color: $themeColor !important;
    }
    .date {
      font-weight: 600;
    }
  }
  .MuiLink-root {
    cursor: pointer;
  }
  // Product Slider
  .product_slider {
    padding: 0px 14px;
    @media (max-width: $mobile) {
      padding: 0;
    }
  }
  .react-multi-carousel-list {
    padding-bottom: 60px;
    .react-multi-carousel-dot--active button {
      background: $themeColor2;
      @media (max-width: $mobile) {
        background: $themeColor;
      }
    }
    .react-multi-carousel-dot button {
      border-color: $themeColor2;
      @media (max-width: $mobile) {
        border-color: $themeColor;
      }
    }
  }
  // Switch
  .MuiSwitch-root {
    width: 55px;
    height: 40px;
  }
  .MuiSwitch-switchBase {
    transform: translateX(5px);
  }
  .MuiSwitch-thumb {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    position: relative;
    top: 4.1px;
  }
  .MuiSwitch-track {
    background-color: $p-color1;
    opacity: 1;
    border-radius: 15px;
    @media (max-width: $mobile) {
      background-color: #bfbfbf;
    }
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: $themeColor;
    opacity: 1;
    @media (max-width: $mobile) {
      background-color: $themeColor2;
    }
  }

  .MuiSwitch-switchBase.Mui-checked {
    color: $white;
  }
  .MuiCheckbox-root,
  .MuiCheckbox-root.Mui-checked {
    color: $themeColor;
    @media (max-width: $mobile) {
      padding-top: 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
  .css-11h4g4n-MuiGrid-root {
    @media (max-width: $mobile) {
      width: 100%;
      margin-left: 0;
    }
  }
  .css-11h4g4n-MuiGrid-root > .MuiGrid-item {
    @media (max-width: $mobile) {
      padding-left: 0px;
    }
  }
  .custom-select {
    .MuiSelect-select.Mui-focused {
      color: $themeColor;
    }
    .css-1480iag-MuiInputBase-root-MuiInput-root {
      &:after {
        border-bottom: 2px solid $themeColor;
      }
    }
    .css-1480iag-MuiInputBase-root-MuiInput-root:hover:not(
        .Mui-disabled
      ):before {
      border-bottom: 1px solid $themeColor;
    }
    .MuiSelect-select {
      &::before {
        content: "";
        background-image: url(../../public/assets/images/down-arrow.svg);
        position: absolute;
        right: 10px;
        bottom: 0;
        top: 12px;
        background-size: cover;
        height: 10px;
        width: 15px;
      }
    }
    svg {
      display: none;
    }
  }

  .MuiAccordion-gutters {
    margin: 16px 0;
  }
  .MuiPaper-root {
    &:before {
      display: none;
    }
  }

  // productdetailpage table
  tr.MuiTableRow-root.MuiTableRow-head .MuiTableCell-root {
    border: none;
    padding: 26px 30px 8px 30px;
    @media (max-width: $mobile) {
      padding: 20px 12px;
    }
  }
  tr.MuiTableRow-root.css-34nofg-MuiTableRow-root .MuiTableCell-root {
    border: none;
    padding: 14px 30px;
    @media (max-width: $mobile) {
      padding: 10px 12px;
    }
  }
  tr.MuiTableRow-root.css-34nofg-MuiTableRow-root:nth-child(8)
    .MuiTableCell-root {
    padding-bottom: 40px;
  }
  .button-primary.Mui-disabled {
    background: #bfbfbf;
    color: #ffffff !important;
  }
  .profile-tabs-accordion {
    .button-primary {
      @media (max-width: $mobile) {
        width: auto;
      }
    }
  }
  .accordion-main {
    .css-ounldo-MuiAccordionDetails-root {
      @media (max-width: $mobile) {
        padding: 16px 0;
      }
    }
  }
  .profile-tab-popup {
    .MuiDialogContent-root {
      max-width: 505px;
      #alert-dialog-description {
        display: flex;
        align-items: flex-start;
        span {
          padding-top: 0;
        }
      }
      .MuiDialogActions-root {
        .MuiButton-root {
          width: 50%;
          font-size: 14px;
        }
      }
    }
  }
  .css-ypiqx9-MuiDialogContent-root {
    padding: 0px;
  }
  .product-filter-accordion {
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
      background-color: transparent;
      padding: 0px 0 20px;
      border-bottom: 1px solid #f2f2f2;
    }
  }
  .show-filter-responsive {
    @media (max-width: $mobile) {
      display: block !important;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      background: white;
      z-index: 9;
      height: 100%;
      padding: 0 25px !important;
    }
  }

  .table-data-wrapper .table-data {
    .p-16,
    .p-18 {
      @media (max-width: $mobile) {
        font-size: 14px;
      }
    }
  }
  .simulator-wrapper {
    @media (max-width: $mobile) {
      padding: 0;
    }
  }

  .css-1yt7yx7-MuiLoadingButton-loadingIndicator {
    left: 19px;
    top: 36%;
  }
  .new-user-graph {
    .Productgraph_product_action_btn__6Vxx5 a {
      width: 100%;
      max-width: 208px;
      @media (max-width: $mobile) {
        width: auto;
        max-width: 100%;
      }
    }
  }
  .dashboad-accordion {
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
      pointer-events: none;
      @media (max-width: $mobile) {
        pointer-events: auto;
      }
      .css-yw020d-MuiAccordionSummary-expandIconWrapper {
        display: none;
        @media (max-width: $mobile) {
          display: block;
        }
      }
    }
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
      @media (max-width: $mobile) {
        margin: 0;
      }
    }
    .css-o4b71y-MuiAccordionSummary-content {
      @media (max-width: $mobile) {
        margin: 0;
      }
    }
    .css-bz4dnt-MuiCollapse-root {
      visibility: visible;
      height: auto;
      @media (max-width: $mobile) {
        visibility: hidden;
        height: 0;
      }
    }
  }

  .accordion-main.Mui-expanded {
    .MuiButtonBase-root {
      border: 2px solid transparent;
      position: relative;
      background-clip: padding-box;
      backdrop-filter: none;
      &::after {
        content: "";
        background: linear-gradient(
          91.94deg,
          #1e22aa -51.99%,
          #e3e3e3 50.09%,
          #1e22aa 136.47%
        );
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
      }
    }
  }
  .accordion-main.Mui-expanded {
    .card-bordered {
      &::before {
        display: none;
      }
    }
  }
  .card-bordered {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 2px;
      border-radius: 8px;
      background: linear-gradient(
        92.05deg,
        #ffffff -0.62%,
        #e3e3e3 50.1%,
        #ffffff 93.02%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      z-index: -1;
    }
  }

  .accordion-main {
    background-color: transparent !important;
    display: grid;
    place-content: center;
    text-align: initial;
    margin: 20px 0px;
    &::before {
      display: none;
    }
    .MuiButtonBase-root {
      background: linear-gradient(
        268.78deg,
        rgba(255, 255, 255, 0.8) 1.52%,
        rgba(255, 255, 255, 0) 39.44%,
        rgba(255, 255, 255, 0.8) 99.9%
      );
      mix-blend-mode: normal;
      border-radius: 8px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 2px;
        border-radius: 8px;
        background: linear-gradient(
          92.05deg,
          #ffffff -0.62%,
          #e3e3e3 50.1%,
          #ffffff 93.02%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        z-index: -1;
      }
    }
    .size-accordion {
      padding: 16px 0;
      margin-left: 14px;
    }
  }

  .MuiFormControl-root {
    .MuiInputLabel-root.Mui-focused {
      color: $themeColor;
    }
    .MuiInput-root {
      &:after {
        border-bottom: 2px solid $themeColor;
      }
    }
    .MuiInput-root:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid $themeColor;
    }
  }

  .page_content {
    position: relative;
    padding: 85px 30px;
    z-index: 10;
    height: 100%;
    background-image: url(../../public/assets/images/background-layout.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (max-width: $mobile) {
      height: 100%;
    }

    @media (max-width: $mobile) {
      padding: 35px 5px;
    }
  }
  .stepper-main-wrapper {
    @media (max-width: $mobile) {
      padding: 0px 5px 30px;
      height: calc(100vh - 91px);
    }
    .stpper-navbar {
      @media (max-width: $mobile) {
        position: absolute;
        left: 0;
        right: 0;
      }
    }
  }
  .backgroundscreen {
    background: rgba(246, 246, 246, 1);
    position: relative;
    padding-bottom: 20px;
    background-image: url(../../public/assets/images/layer-login.svg);
    background-repeat: no-repeat;
    padding-bottom: 30px;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
  }
  .h2 {
    font-size: $h2;
    color: $h2-color;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 12px;
    font-weight: $fweight;
    @media (max-width: $mobile) {
      font-size: 20px;
      justify-content: left;
      margin-bottom: 0;
    }
  }
  .p-center {
    text-align: $center;
    margin-bottom: 0.8rem;
    @media (max-width: $mobile) {
      text-align: left;
    }
  }

  .password-hint-wrapper {
    display: flex;
  }

  //helpertextof register
  p#standard-basic-helper-text {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  p#standard-basic-helper-text svg {
    width: 18px;
  }

  .objective_navbar {
    padding: 40px 0;
    position: relative;
    @media (max-width: $mobile) {
      padding: 1px 0;
    }
    .stpper-navbar {
      @media (max-width: $mobile) {
        position: absolute;
        left: 0;
        right: 0;
      }
      .MuiGrid-item {
        flex-basis: 30%;
        max-width: 30%;
        @media (max-width: $mobile) {
          flex-basis: calc(33.33% - 3px);
          max-width: calc(33.33% - 3px);
          margin-right: 3px !important;
        }
      }
      span {
        margin: 0 auto;
      }
    }
    .card-icon {
      svg {
        font-size: 30px;
        color: $green-color;
      }
    }
    button {
      max-width: 360px;
      width: 100%;
      display: block;
      justify-content: center;
      margin: 20px auto;
    }
    .objective-field {
      background: #ffffff;
      border-radius: 8px;
      padding: 20px 20px;
      margin: 70px 0 50px;
    }
  }

  .complete_registration_navbar {
    padding: 40px 0 10px;
    background: #ffffff;
    border-radius: 20px;
    @media (max-width: $mobile) {
      border-radius: 8px;
      padding: 40px 0 20px;
    }
    .stpper-navbar {
      .MuiGrid-item {
        flex-basis: 22.3%;
        max-width: 22.3%;
      }
      span {
        margin: 0 auto;
      }
    }
    .stepper-grid-registration {
      margin-top: 40px;
      @media (max-width: $mobile) {
        margin-top: 20px;
      }
    }
  }
  .compete_registration {
    .stepper:first-child::before {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }

  .padding-btn {
    a {
      padding: 10px 30px;
      font-weight: 500;
      @media (max-width: $mobile) {
        font-size: 16px;
        padding: 10px 15px;
        display: block;
      }
    }
    @media (max-width: $mobile) {
      text-align: center;
      padding-top: 30px;
    }
  }
  .new-user-graph {
    .formbutton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: $mobile) {
        display: block;
      }
      button {
        max-width: 280px;
        @media (max-width: $mobile) {
          max-width: 100%;
          margin-top: 10px;
        }
      }
      a {
        @media (max-width: $mobile) {
          max-width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
  .makeStyles-indicators-4 {
    margin-top: 30px !important;
    @media (max-width: $mobile) {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      text-align: center;
      max-width: 25px;
      margin: 0 auto;
    }

    .makeStyles-active-7 {
      color: #49a197;
    }
  }

  .css-11h4g4n-MuiGrid-root {
    @media (max-width: $mobile) {
      width: 100%;
      margin-left: 0;
    }
  }

  /* Required styles */
  .swipe-to-delete {
    position: relative !important;
    padding: 0 !important;
    overflow: hidden !important;

    .js-delete {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      z-index: 1 !important;
      opacity: 0;
      @media (max-width: $mobile) {
        opacity: 1;
      }
    }
    .js-content {
      position: relative;
      z-index: 2 !important;
    }

    .js-content :first-child {
      position: relative !important;
    }

    .js-transition-delete-right,
    .js-transition-delete-left,
    .js-transition-cancel {
      transition-property: left !important;
      transition-duration: 0.5s;
    }
    .js-transition-delete-right {
      left: 100% !important;
    }
    .js-transition-delete-left {
      left: -100% !important;
    }
    .js-transition-cancel {
      left: 0 !important;
    }
  }

  /* Custom styles */
  .delete-noti {
    display: flex;
    align-items: center;
  }
  .swipe-to-delete {
    margin-bottom: 15px !important;
    .js-delete {
      $horiz-padding: 17px;
      background: rgba(209, 45, 53, 0.1);
      border-radius: 8px;
      svg {
        position: absolute;
        left: $horiz-padding;
        top: 50%;
        margin-top: -13px;
        width: 25px;
        height: 25px;
      }
      svg:first-child {
        left: auto;
        right: $horiz-padding;
      }
    }

    .js-content :first-child {
      cursor: pointer;
    }

    .js-transition-delete-right,
    .js-transition-delete-left,
    .js-transition-cancel {
      transition-duration: 0.7s;
      transition-timing-function: ease-in-out;
    }
  }
}
