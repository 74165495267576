.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swipper-dashboard {
  border-radius: 8px;
}

.custom-otp-input {
  display: flex !important;
  gap: "6px" !important;
}
.custom-otp-input input {
  border-radius: 4px;
  width: 35px !important;
  height: 35px !important;
  margin-right: 10px !important;
  border: 1px solid #1e22aa !important;
}
.custom-otp-input input:last-of-type {
  margin-right: 0px !important;
}

@media (width >= 1200px) {
  .custom-otp-input input {
    width: 50px !important;
    height: 50px !important;
    margin-right: 20px !important;
  }
}

@media screen and (max-width: 790px) {
  #tidio-chat-iframe {
    inset: auto 8px 80px auto !important;
  }
}
